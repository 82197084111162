import React, { useEffect, useState } from 'react';
import { Table, Button, Dropdown, message, Tag } from 'antd';
import api from '../../config/api';
import AttendanceModal from './components/AttendanceModal';
import { MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { fetchEvents, fetchTeams, fetchPlayersByTeam, fetchAttendanceByEvent, updateAttendanceByEvent } from './api/attendance';


const AttendancePage = () => {
    const [events, setEvents] = useState([]);
    const [teams, setTeams] = useState([]);
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [initialAttendance, setInitialAttendance] = useState([]);

    useEffect(() => {
        const fetchEventsAndTeams = async () => {
            try {
                const eventsResponse = await fetchEvents();
                console.log(eventsResponse)
                const sortedEvents = eventsResponse.data.sort((a, b) => {
                    // First, compare dates
                    const dateA = dayjs(a.date, "YYYY-MM-DD").valueOf();
                    const dateB = dayjs(b.date, "YYYY-MM-DD").valueOf();

                    if (dateA !== dateB) {
                        // If the dates are different, sort by date
                        return dateA - dateB;
                    }

                    // If the dates are the same, compare start times
                    const timeA = a.start_time ? dayjs(a.start_time, "HH:mm:ss").valueOf() : 0; // Handle null start_time as earliest
                    const timeB = b.start_time ? dayjs(b.start_time, "HH:mm:ss").valueOf() : 0;

                    return timeA - timeB; // Sort by start time
                });
                setEvents(sortedEvents);
                setEvents(eventsResponse.data);

                // Fetch teams
                const teamsResponse = await fetchTeams();
                setTeams(teamsResponse.data);
            } catch (error) {
                console.error("Error fetching events or teams:", error);
                message.error("Failed to fetch events or teams.");
            } finally {
                setLoading(false);
            }
        };

        fetchEventsAndTeams();
    }, []);

    const fetchPlayers = async (event) => {
        try {
            const playersResponse = await fetchPlayersByTeam(event.team_id);
            setPlayers(playersResponse.data);
            setSelectedEvent(event);
            setModalVisible(true);

            const attendanceResponse = await fetchAttendanceByEvent(event.event_id);
            setInitialAttendance(attendanceResponse.data);
        } catch (error) {
            console.error("Error fetching players or attendance:", error);
            message.error("Failed to fetch data.");
        }
    };

    const handleAttendanceSubmit = async (attendanceData) => {
        try {
            await updateAttendanceByEvent(selectedEvent.event_id, attendanceData);
            message.success('Attendance updated successfully');
            setModalVisible(false);
        } catch (error) {
            console.error("Error updating attendance:", error);
            message.error('Failed to update attendance');
        }
    };
    const getEntityTypeColor = (entityType) => {
        switch (entityType) {
            case "Match":
                return "red";
            case "Meeting":
                return "blue";
            case "Session":
                return "green";
            default:
                return "gray";
        }
    };
    function capitalizeFirstLetter(string) {
        if (typeof string !== 'string' || string.length === 0) {
            return '';  // Return an empty string if input is undefined or not a string
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const columns = [
        {
            title: 'Event Name',
            dataIndex: 'event_name',
            key: 'event_name',
        },
        {
            title: 'Event Type', // Moved Event Type column before Team Name
            dataIndex: 'entity_type',
            key: 'entity_type',
            render: (text) => {
                const entityTypeColor = getEntityTypeColor(text); // Get color based on entity type
                return <Tag color={entityTypeColor}>{capitalizeFirstLetter(text)}</Tag>; // Render with Tag
            },
        },
        {
            title: 'Team Name', // Team Name column follows Event Type
            dataIndex: 'team_name',
            key: 'team_name',
            render: (text, record) => {
                const team = teams.find(team => team.team_id === record.team_id);
                return team ? (
                    <Tag color={team.team_color ? team.team_color.toLowerCase() : "blue"}>
                        {team.team_name}
                    </Tag>
                ) : "N/A"; // Display team name in tag with color, or N/A if not found
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => dayjs(date).format('YYYY-MM-DD'), // Format the date to show only YYYY-MM-DD
        },
        {
            title: 'Actions',
            key: "actions",
            render: (_, record) => (
                <Dropdown menu={actionMenu(record)} trigger={["click"]}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const actionMenu = (record) => ({
        items: [
            {
                key: "mark-attendance",
                label: "Mark Attendance",
                onClick: () => fetchPlayers(record), // Open the modal to mark attendance
            },
        ],
    });

    return (
        <div style={{
            height: "calc(100vh - 48px)", // Account for padding
            overflowY: "auto",
            margin: "-24px",  // Offset the default Content padding
            padding: "24px"   // Restore padding on this element
        }}>
            <Table
                columns={columns}
                dataSource={events}
                loading={loading}
                rowKey="event_id"
                pagination={{
                    pageSize: 10,
                    position: ["bottomCenter"]
                }}
            />
            <AttendanceModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                players={players}
                onSubmit={handleAttendanceSubmit}
                initialAttendance={initialAttendance}
            />
        </div>
    );
};

export default AttendancePage;
