import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Space, Dropdown, message } from "antd";
import { MoreOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import TeamForm from "./components/forms/TeamForm";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { createTeam, deleteTeam, getTeams, getUserById, updateTeam } from './api/teams';

const { confirm } = Modal;

const TeamsPage = () => {
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);


  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await getTeams();
        const teamsData = await Promise.all(response.data.map(async (team) => {

          return {
            key: team.team_id,
            teamName: team.team_name,
            startDate: team.start_date ? dayjs(team.start_date).toISOString() : null,
            endDate: team.end_date ? dayjs(team.end_date).toISOString() : null,
            teamSize: team.team_size,
            team_color: team.team_color,
          };
        }));

        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
        message.error("Failed to fetch teams data.");
      }
    };

    fetchTeams();
  }, []);

  const columns = [
    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
      render: (team, record) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/teams/${record.key}`);
          }}
        >
          {team}
        </a>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => text ? dayjs(text).format("YYYY-MM-DD") : 'N/A'
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => text ? dayjs(text).format("YYYY-MM-DD") : 'N/A'
    },
    {
      title: "Team Size",
      dataIndex: "teamSize",
      key: "teamSize",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown menu={actionMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const actionMenu = (record) => ({
    items: [
      { key: "view", label: "View", onClick: () => handleView(record) },
      { key: "edit", label: "Edit", onClick: () => handleEdit(record) },
      { key: "delete", label: "Delete", onClick: () => handleDelete(record) },
    ],
  });

  const handleView = (record) => navigate(`/teams/${record.key}`);

  const handleEdit = (record) => {
    setEditingTeam({
      ...record,
      startDate: record.startDate ? dayjs(record.startDate) : null,
      endDate: record.endDate ? dayjs(record.endDate) : null,
    });
    setIsModalOpen(true);
  };

  const handleDelete = (record) => {
    confirm({
      title: "Are you sure you want to delete this team?",
      icon: <ExclamationCircleOutlined />,
      content: `Team: ${record.teamName}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deleteTeam(record.key);
          setTeams((teams) => teams.filter((team) => team.key !== record.key));
          message.success("Team deleted successfully");
        } catch (error) {
          console.error("Error deleting team:", error);
          message.error("Failed to delete team.");
        }
      },
    });
  };

  const handleAddTeam = () => {
    setEditingTeam(null);
    setIsModalOpen(true);
  };

  const handleSaveTeam = async (teamData) => {
    try {
      const payload = {
        team_name: teamData.team_name,
        start_date: teamData.start_date ? dayjs(teamData.start_date).format("YYYY-MM-DD") : null,
        end_date: teamData.end_date ? dayjs(teamData.end_date).format("YYYY-MM-DD") : null,
        team_size: teamData.team_size,
        team_color: teamData.team_color,
      };

      let updatedTeam;

      if (editingTeam) {
        // Update team
        await updateTeam(editingTeam.key, payload);
        updatedTeam = {
          ...editingTeam,
          teamName: teamData.team_name,
          startDate: payload.start_date ? dayjs(payload.start_date).toISOString() : null,
          endDate: payload.end_date ? dayjs(payload.end_date).toISOString() : null,
          teamSize: teamData.team_size,
          team_color: teamData.team_color,
        };
        setTeams((teams) =>
          teams.map((team) => (team.key === editingTeam.key ? updatedTeam : team))
        );
      } else {
        // Create new team
        const response = await createTeam(payload);
        updatedTeam = {
          key: response.data.team_id,
          teamName: teamData.team_name,
          startDate: payload.start_date ? dayjs(payload.start_date).toISOString() : null,
          endDate: payload.end_date ? dayjs(payload.end_date).toISOString() : null,
          teamSize: teamData.team_size,
          team_color: teamData.team_color,
        };
        setTeams((teams) => [...teams, updatedTeam]);
      }

      message.success("Team saved successfully");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error saving team:", error);
      message.error("Failed to save team.");
    }
  };

  return (
    <div style={{
      height: "calc(100vh - 48px)",
      overflowY: "auto",
      margin: "-24px",
      padding: "24px",
      position: "relative"
    }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setIsModalOpen(true);
          setEditingTeam(null);
        }}
        style={{ marginBottom: 16 }}
      >
        Create Team
      </Button>
      <Table
        columns={columns}
        dataSource={teams}
        rowKey="key"
        pagination={{
          pageSize: 10,
          position: ["bottomCenter"]
        }}
      />

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        destroyOnClose
      >
        <TeamForm
          initialValues={editingTeam}
          onCancel={() => setIsModalOpen(false)}
          onFinish={handleSaveTeam}
        />
      </Modal>
    </div>
  );
};

export default TeamsPage;
