import api from "../../../config/api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Descriptions, Spin, message, Tabs, Tag } from "antd";
import dayjs from "dayjs";
import Roster from "./Roster";
import InjuryPage from "./Injuries";
import { getTeamById, getUserById } from '../api/teams';

const { TabPane } = Tabs;

const Team = () => {
  const { teamId } = useParams();
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("TeamDetails component mounted with teamId:", teamId); // Log teamId on component mount

    const fetchTeam = async () => {
      try {
        // Fetch team data
        const response = await getTeamById(teamId);
        const teamData = response.data;
        console.log("Fetched team data:", teamData); // Log fetched team data

        const headCoach = await fetchUserName(teamData.head_coach);
        const assistantCoach = await fetchUserName(teamData.assistant_coach);
        const teamManager = await fetchUserName(teamData.team_manager);

        setTeam({
          teamName: teamData.team_name,
          startDate: teamData.start_date,
          endDate: teamData.end_date,
          teamSize: teamData.team_size,
          headCoach,
          team_color: teamData.team_color,
          assistantCoach: assistantCoach || "N/A",
          teamManager: teamManager || "N/A",
        });
      } catch (error) {
        console.error("Error fetching team details:", error);
        message.error("Failed to fetch team details.");
      } finally {
        setLoading(false);
      }
    };

    const fetchUserName = async (userId) => {
      if (!userId) return "N/A";
      try {
        const userResponse = await getUserById(userId);
        const user = userResponse.data;
        console.log(`Fetched user data for userId ${userId}:`, user); // Log user data
        return `${user.first_name} ${user.last_name}`;
      } catch (error) {
        console.error(`Error fetching user with ID ${userId}:`, error);
        return "N/A";
      }
    };

    fetchTeam();
  }, [teamId]);

  if (loading)
    return <Spin size="large" style={{ display: "block", margin: "auto", marginTop: "20%" }} />;

  if (!team)
    return <div style={{ textAlign: "center", marginTop: "20px" }}>Team not found</div>;

  console.log("Rendering InjuryPage with teamId:", teamId); // Log before rendering InjuryPage component

  return (
    <div style={{
      height: "calc(100vh - 48px)",
      overflowY: "auto",
      margin: "-24px",
      padding: "24px",
      position: "relative",
      background: "#fff"
    }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Overview" key="1">
          <Descriptions bordered column={1} style={{ marginBottom: 16 }}>
            <Descriptions.Item label="Team Name">
              <Tag color={team.team_color ? team.team_color.toLowerCase() : "blue"}>{team.teamName}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Start Date">
              {dayjs(team.startDate).format("YYYY-MM-DD")}
            </Descriptions.Item>
            <Descriptions.Item label="End Date">
              {dayjs(team.endDate).format("YYYY-MM-DD")}
            </Descriptions.Item>
            <Descriptions.Item label="Team Size">{team.teamSize}</Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab="Roster" key="2">
          <Roster teamId={teamId} />
        </TabPane>
        <TabPane tab="Injuries" key="3">
          <InjuryPage teamId={teamId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Team;
