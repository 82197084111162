import React from "react";
import { Table, Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const { Link } = Typography;

const DrillTable = ({ sessionDrills, drillFilesCount, handleViewFiles, handleEditDrill, handleDeleteDrill }) => {
  const columns = [
    {
      title: "Drill Name",
      dataIndex: "drill_name",
      key: "name",
      render: (text, record) => (
        <Link
          onClick={() => handleViewFiles(record)}
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            lineHeight: '1.5',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {text}
        </Link>
      ),
      width: 200,
      ellipsis: {
        showTitle: false
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => `${duration} min`,
      width: 100,
      align: 'center'
    },
    {
      title: "Files",
      key: "files",
      render: (_, record) => drillFilesCount[record.drill_id] || 0,
      width: 80,
      align: 'center'
    },
    {
      title: "Actions",
      key: "actions",
      width: 80,
      align: 'center',
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handleEditDrill(record)}>
                Edit
              </Menu.Item>
              <Menu.Item onClick={() => handleDeleteDrill(record.drill_id)}>
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={sessionDrills}
      pagination={{
        pageSize: 10,
        position: ["bottomCenter"]
      }}
      rowKey="drill_id"
      style={{ marginTop: 16 }}
    />
  );
};

export default DrillTable;