import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Tabs, Descriptions, message } from "antd";
import Plan from "./SessionPlan";
import Resources from "./Resources";
import Notes from "./Notes";
import { getSessionById, getSessionDrills, getSessionDuration, getSessionFiles } from '../api/sessions';
import { useAuth } from "../../../hooks/useAuth";


const { TabPane } = Tabs;

const Session = () => {
  const { sessionId } = useParams();
  const [session, setSession] = useState(null);
  const [sessionLoading, setSessionLoading] = useState(true);
  const [totalDuration, setTotalDuration] = useState(0);
  const [drillCount, setDrillCount] = useState(0);
  const [resourceCount, setResourceCount] = useState(0);
  const [resourceLoading, setResourceLoading] = useState(true);
  const [resourceError, setResourceError] = useState(null);
  const { user, loading } = useAuth();

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        // Fetch session information
        const sessionResponse = await getSessionById(sessionId);
        setSession(sessionResponse.data);

        // Fetch drills related to the session
        const drillsResponse = await getSessionDrills(sessionId);
        const drills = drillsResponse.data || [];
        setDrillCount(drills.length); // Count of drills

        // Calculate total duration
        const totalDurationResponse = await getSessionDuration(sessionId);
        setTotalDuration(totalDurationResponse.data.totalDuration || 0); // Update total duration from API response

        // Fetch session files to determine resource count
        const filesResponse = await getSessionFiles(sessionId);
        setResourceCount(filesResponse.data.length);
      } catch (error) {
        console.error("Error fetching session details:", error);
        setResourceError("Failed to load resources.");
      } finally {
        setResourceLoading(false);
        setSessionLoading(false);
      }
    };

    fetchSessionDetails();
  }, [sessionId]);

  const updateResourceCount = (newCount) => {
    setResourceCount(newCount);
  };

  const updateDrillCount = (newCount) => {
    setDrillCount(newCount);
  };

  const updateTotalDuration = (newDuration) => {
    setTotalDuration(newDuration);
  };

  if (sessionLoading) {
    return <p>Loading session data...</p>;
  }

  if (!session) {
    return <p>Session not found.</p>;
  }

  return (
    <div style={{
      height: "calc(100vh - 48px)",
      overflowY: "auto",
      margin: "-24px",
      padding: "24px",
      position: "relative",
      background: "#fff",
      overflowX: "auto"
    }}>
      <Tabs
        defaultActiveKey="1"
        style={{
          '& .ant-tabs-content': {
            height: 'auto !important',
            overflow: 'visible !important'
          }
        }}
      >
        <TabPane
          tab="Details"
          key="1"
          style={{ overflow: 'visible' }}
        >
          <div style={{ minWidth: 'fit-content' }}>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="Session Name">{session.session_name}</Descriptions.Item>
              <Descriptions.Item label="Goals">{session.goals}</Descriptions.Item>
              <Descriptions.Item label="Resources">
                {resourceLoading ? "Loading resources..." : resourceError || `${resourceCount} Resources`}
              </Descriptions.Item>
              <Descriptions.Item label="Drills">{`${drillCount} Drills`}</Descriptions.Item>
              <Descriptions.Item label="Total Duration">{`${totalDuration} min`}</Descriptions.Item>
            </Descriptions>
          </div>
        </TabPane>

        <TabPane
          tab="Drills"
          key="2"
          style={{ overflow: 'visible' }}
        >
          <div style={{ minWidth: 'fit-content' }}>
            <Plan
              sessionId={sessionId}
              setTotalDuration={updateTotalDuration}
              setDrillCount={updateDrillCount}
            />
          </div>
        </TabPane>

        <TabPane
          tab="Resources"
          key="3"
          style={{ overflow: 'visible' }}
        >
          <div style={{ minWidth: 'fit-content' }}>
            <Resources
              sessionId={sessionId}
              updateResourceCount={updateResourceCount}
            />
          </div>
        </TabPane>

        <TabPane
          tab="Notes"
          key="4"
          style={{ overflow: 'visible' }}
        >
          <div style={{ minWidth: 'fit-content' }}>
            <Notes sessionId={sessionId} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Session;
