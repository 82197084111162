import React, { useEffect, useState } from "react";
import { Form, Input, Button, DatePicker, Select, Checkbox, message, Empty } from "antd";
import dayjs from "dayjs";
import { getPlayersByTeamId, getTeamById, getUserById } from "../../api/teams";

const { TextArea } = Input;
const { Option } = Select;

const InjuryForm = ({ teamId, initialValues, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [players, setPlayers] = useState([]);
  const [staff, setStaff] = useState([]); // Holds team members: head coach, assistant coach, manager
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  useEffect(() => {
    // Fetch players for the selected team
    const fetchPlayers = async () => {
      try {
        const playerResponse = await getPlayersByTeamId(teamId);
        if (playerResponse.data && playerResponse.data.length > 0) {
          setPlayers(playerResponse.data);
        } else {
          setPlayers([]);
          message.warning("No players found for the selected team.");
        }
      } catch (error) {
        message.error("Failed to load players.");
      }
    };
    fetchPlayers();
  }, [teamId]);

  // Format initial values for the form, converting date string to dayjs if present
  const formInitialValues = initialValues
    ? {
      ...initialValues,
      date: initialValues.date ? dayjs(initialValues.date, "YYYY-MM-DD") : null,
    }
    : {};

  const onFinish = async (values) => {
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true); // Set submitting state

    try {
      const data = {
        ...values,
        team_id: teamId,
        date: values.date ? values.date.format("YYYY-MM-DD") : null,
      };
      await onSave(data); // Wait for the save function to complete
      // message.success("Injury record submitted successfully!");
    } catch (error) {
      console.error("Error submitting injury record:", error);
      message.error("Failed to submit the injury record.");
    } finally {
      setIsSubmitting(false); // Reset the submitting state
    }
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={formInitialValues}
      style={{
        maxHeight: 'calc(100vh - 150px)',
        overflowY: 'auto',
        padding: '0 10px'
      }}
    >
      <Form.Item
        name="player_id"
        label="Player"
        rules={[{ required: true, message: "Please select a player" }]}
      >
        <Select placeholder="Select player">
          {players.length > 0 ? (
            players.map((player) => (
              <Option key={player.player_id} value={player.player_id}>
                {player.player_name}
              </Option>
            ))
          ) : (
            <Option disabled value="">
              No players available
            </Option>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        name="injury_status"
        label="Injury Status"
        rules={[{ required: true, message: "Please select the injury status" }]}
      >
        <Select placeholder="Select injury status">
          <Option value="injured">Injured</Option>
          <Option value="day to day">Day To Day</Option>
          <Option value="out for season">Out For Season</Option>
          <Option value="fully fit">Fully Fit</Option>
          <Option value="medical clearance pending">Medical Clearance Pending</Option>
          <Option value="cleared for light activity">Cleared for Light Activity</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="injury_type"
        label="Injury Type"
        rules={[{ required: true, message: "Please enter the injury type" }]}
      >
        <Input placeholder="Enter injury type" />
      </Form.Item>

      <Form.Item
        name="injury_description"
        label="Injury Description"
      >
        <TextArea placeholder="Enter injury description (Optional)" rows={4} />
      </Form.Item>

      <Form.Item
        name="management"
        label="Management"
      >
        <TextArea placeholder="Enter management details (Max 250 Characters) (Optional)" rows={4} />
      </Form.Item>

      <Form.Item
        name="date"
        label="Injury Date"
        rules={[{ required: true, message: "Please select the injury date" }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="follow_up"
        valuePropName="checked"
      >
        <Checkbox>Requires Follow-Up</Checkbox>
      </Form.Item>

      <Form.Item
        style={{
          textAlign: "right",
          marginBottom: 0,
          position: 'sticky',
          bottom: 0,
          backgroundColor: '#fff',
          padding: '10px 0',
          borderTop: '1px solid #f0f0f0'
        }}
      >
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InjuryForm;
