// src/components/Roster.jsx

import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  Dropdown,
  Menu,
  Spin,
  Space,
} from "antd";
import {
  MoreOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { createPlayer, deletePlayer, getPlayersByTeamId, updatePlayer } from '../api/teams';
import PlayerProfileModal from './PlayerProfileModal'; // Import the modal
import { useAuth } from '../../../hooks/useAuth'; // Import useAuth


const { confirm } = Modal;
const { Option } = Select;


// PlayerForm Component
const PlayerForm = ({ initialValues, onSave, onCancel }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        jerseyNumber: initialValues.jerseyNumber,
        name: initialValues.name,
        position: initialValues.position,
        preferredFoot: initialValues.preferredFoot,
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const onFinish = (values) => {
    onSave(values);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="playerForm"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="jerseyNumber"
        label="Jersey Number"
        rules={[
          { required: true, message: "Please enter the jersey number" },
          {
            type: "number",
            min: 1,
            message: "Jersey number must be at least 1",
          },
        ]}
      >
        <InputNumber
          min={1}
          style={{ width: "100%" }}
          placeholder="Enter Jersey Number"
        />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: "Please enter the player name" },
          { whitespace: true, message: "Name cannot be empty" },
        ]}
      >
        <Input placeholder="Enter Player Name" />
      </Form.Item>

      <Form.Item
        name="position"
        label="Position"
        rules={[
          { required: true, message: "Please enter the player position" },
          { whitespace: true, message: "Position cannot be empty" },
        ]}
      >
        <Input placeholder="Enter Player Position" />
      </Form.Item>

      <Form.Item
        name="preferredFoot"
        label="Preferred Foot"
        rules={[
          { required: true, message: "Please select the preferred foot" },
        ]}
      >
        <Select placeholder="Select Preferred Foot">
          <Option value="Left">Left</Option>
          <Option value="Right">Right</Option>
          <Option value="Both">Both</Option>
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "right" }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>Cancel</Button>
        <Button type="primary" htmlType="submit" >
          {initialValues ? "Update" : "Add"}
        </Button>
      </Form.Item>
    </Form>
  );
};

// Roster Component
const Roster = ({ teamId }) => {
  const { user } = useAuth(); // Get user from useAuth
// Extract userId safely
  const [players, setPlayers] = useState([]); // State to hold players data
  const [loading, setLoading] = useState(true); // Loading state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingPlayer, setEditingPlayer] = useState(null);
  const hasWarned = useRef(false); // Ref to track if warning has been shown

  // New states for PlayerProfileModal
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  // Fetch players when component mounts or teamId changes
  useEffect(() => {
    // Reset the warning flag when teamId changes
    hasWarned.current = false;
    const fetchPlayers = async () => {
      setLoading(true);
      try {
        const response = await getPlayersByTeamId(teamId);

        const playersData = response.data.length
          ? response.data.map((player) => ({
            key: player.player_id, // Unique identifier
            jerseyNumber: player.jersey_no,
            name: player.player_name,
            position: player.position,
            preferredFoot: player.preferred_foot,
          }))
          : [];  // Set to an empty array if no players found

        setPlayers(playersData);

        if (!playersData.length && !hasWarned.current) {
          message.info("No players found for this team.");  // Display message if no players are found
          hasWarned.current = true;
        }
      } catch (error) {
        console.error("Error fetching players:", error);
        message.error("Failed to fetch players.");
      } finally {
        setLoading(false);
      }
    };
    fetchPlayers();
  }, [teamId]);

  // Define table columns
  const columns = [
    {
      title: "Jersey Number",
      dataIndex: "jerseyNumber",
      key: "jerseyNumber",
      sorter: (a, b) => a.jerseyNumber - b.jerseyNumber,
      sortDirections: ["ascend", "descend"],
      width: 120,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      width: 200,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      filters: [
        { text: "Forward", value: "Forward" },
        { text: "Midfielder", value: "Midfielder" },
        { text: "Defender", value: "Defender" },
        { text: "Goalkeeper", value: "Goalkeeper" },
        // Add more positions as needed
      ],
      onFilter: (value, record) => record.position === value,
      width: 150,
    },
    {
      title: "Preferred Foot",
      dataIndex: "preferredFoot",
      key: "preferredFoot",
      filters: [
        { text: "Left", value: "Left" },
        { text: "Right", value: "Right" },
        { text: "Both", value: "Both" },
      ],
      onFilter: (value, record) => record.preferredFoot === value,
      width: 150,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
      width: 100,
    },
  ];

  // Define the action menu for each player
  const actionMenu = (record) => (
    <Menu>
      <Menu.Item key="view" onClick={() => handleView(record)}>
        View
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handleEdit(record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDelete(record)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  // Handler for viewing a player
  const handleView = (record) => {
    setSelectedPlayer(record);
    setProfileModalVisible(true);
  };

  // Handler for editing a player
  const handleEdit = (record) => {
    setEditingPlayer(record);
    setIsModalVisible(true);
  };

  // Handler for deleting a player
  const handleDelete = (record) => {
    confirm({
      title: "Are you sure you want to delete this player?",
      icon: <ExclamationCircleOutlined />,
      content: `Player: ${record.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await deletePlayer(record.key);
          setPlayers(players.filter((player) => player.key !== record.key));
          message.success("Player deleted successfully");
        } catch (error) {
          console.error("Error deleting player:", error);
          message.error("Failed to delete player.");
        }
      },
      onCancel() {
        // Do nothing
      },
    });
  };

  // Handler for adding a new player
  const handleAddPlayer = () => {
    setEditingPlayer(null);
    setIsModalVisible(true);
  };

  // Handler for saving a player (both create and edit)
  const handleSavePlayer = async (playerData) => {
    console.log("test uuid",user)
    const userId = user?.id; 
    console.log("test uuid",userId)
    try {
      if (editingPlayer) {
        // Edit existing player
        const payload = {
          player_name: playerData.name,
          jersey_no: playerData.jerseyNumber,
          position: playerData.position,
          preferred_foot: playerData.preferredFoot,
          team_id: teamId,
          created_by: userId
        };
        const response = await updatePlayer(
          editingPlayer.key,
          payload
        );
        const updatedPlayer = {
          key: response.data.player_id,
          jerseyNumber: response.data.jersey_no,
          name: response.data.player_name,
          position: response.data.position,
          preferredFoot: response.data.preferred_foot,
        };
        setPlayers(
          players.map((player) =>
            player.key === editingPlayer.key ? updatedPlayer : player
          )
        );
        message.success("Player updated successfully");
      } else {
        // Create new player
        const payload = {
          player_name: playerData.name,
          jersey_no: playerData.jerseyNumber,
          position: playerData.position,
          preferred_foot: playerData.preferredFoot,
          team_id: teamId,
          created_by: userId
        };
        const response = await createPlayer(
          payload
        );
        const newPlayer = {
          key: response.data.player_id,
          jerseyNumber: response.data.jersey_no,
          name: response.data.player_name,
          position: response.data.position,
          preferredFoot: response.data.preferred_foot,
          created_by: userId
        };
        setPlayers([...players, newPlayer]);
        message.success("Player added successfully");
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving player:", error);
      message.error("Failed to save player.");
    }
  };

  if (loading)
    return (
      <Spin
        size="large"
        style={{ display: "block", margin: "auto", marginTop: "20px" }}
      />
    );

  return (
    <div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddPlayer}
        style={{ marginBottom: 16 }}
      >
        Add Player
      </Button>
      {players.length > 0 ? (
        <Table
          columns={columns}
          dataSource={players}
          rowKey="key"
          pagination={{ pageSize: 10 }}
        />
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          No players found for this team.
        </div>
      )}

      {/* PlayerForm Modal for Add/Edit */}
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <PlayerForm
          initialValues={editingPlayer}
          onSave={handleSavePlayer}
          onCancel={() => setIsModalVisible(false)}
        />
      </Modal>

      {/* PlayerProfileModal for View */}
      {selectedPlayer && (
        <PlayerProfileModal
          visible={profileModalVisible}
          onClose={() => setProfileModalVisible(false)}
          player={selectedPlayer}
        />
      )}
    </div>
  );
};

export default Roster;
