import React from "react";
import { Card, List, Tag, Space, Typography, Button, Empty } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

const { Text } = Typography;

const UpcomingEvents = ({ events, teams }) => {


  // Sort events in chronological order
  const sortedEvents = events.sort((a, b) => {
    // Compare dates (YYYY-MM-DD only)
    const dateA = dayjs(a.date, "YYYY-MM-DD").valueOf();
    const dateB = dayjs(b.date, "YYYY-MM-DD").valueOf();

    if (dateA !== dateB) {
      // If the dates are different, sort by date
      return dateA - dateB;
    }

    // If the dates are the same, compare times
    const timeA = a.start_time ? dayjs(a.start_time, "HH:mm:ss").valueOf() : 0;
    const timeB = b.start_time ? dayjs(b.start_time, "HH:mm:ss").valueOf() : 0;

    return timeA - timeB; // Sort by start time
  });
  console.log("Sorted Events:", sortedEvents);
  const getTeamDetails = (teamId) => {
    const team = teams.find(t => t.team_id === teamId);
    return team ? { name: team.team_name, color: team.team_color } : { name: "Unknown", color: "gray" };
  };

  const getEntityTypeColor = (entityType) => {
    switch (entityType) {
      case "Match":
        return "red";
      case "Meeting":
        return "blue";
      case "Session":
        return "green";
      default:
        return "gray";
    }
  };

  return (
    <Card>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        gap: '8px',
        flexWrap: 'nowrap'  // Prevent wrapping
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <CalendarOutlined />
          <Typography.Title level={5} style={{ margin: 0 }}>Upcoming Events</Typography.Title>
        </div>
        <Link to="/calendar">View All</Link>
      </div>

      {events.length === 0 ? (
        <Empty description="No upcoming events" />
      ) : (
        <div
          style={{
            maxHeight: "70vh", // Set maximum height
            overflowY: "auto", // Enable vertical scroll
            paddingRight: "8px", // Add padding to avoid scroll bar overlap
          }}
        >
          <List
            dataSource={events}
            renderItem={(item) => {
              const { name: teamName, color: teamColor } = getTeamDetails(item.team_id);
              const entityTypeColor = getEntityTypeColor(item.entity_type);
              const startDate = item.date ? dayjs(item.date).format("YYYY-MM-DD") : "Date TBD";
              const startTime = item.start_time ? dayjs(item.start_time, "HH:mm:ss").format("HH:mm") : "Time TBD";
              const endTime = item.end_time ? dayjs(item.end_time, "HH:mm:ss").format("HH:mm") : "Time TBD";
              return (
                <List.Item
                  style={{
                    marginBottom: "8px",
                    borderRadius: "4px",
                    padding: "12px",
                    backgroundColor: "#fff",
                  }}
                >
                  <List.Item.Meta
                    title={
                      <Space>
                        <Text strong>
                          {item.event_name}
                        </Text>
                        <Tag color={teamColor ? teamColor.toLowerCase() : "gray"}>{teamName}</Tag>

                        <Tag color={entityTypeColor}>{item.entity_type}</Tag>
                      </Space>
                    }
                    description={
                      <>
                        <Text type="secondary">
                          {startDate}
                        </Text>
                        <br />
                        <Text type="secondary">
                          {startTime} - {endTime}
                        </Text>
                        <br />
                        <Text>{item.location || "Location TBD"}</Text>
                        <br />
                        {item.entity_type === "Session" && item.entity_id ? (
                          <Text>
                            <Link to={`/sessions/${item.entity_id}`}>View Session</Link>
                          </Text>
                        ) : null}
                      </>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </div>
      )}
    </Card>
  );
};

export default UpcomingEvents;
