import api from "../../config/api";
import React, { useState, useEffect } from "react";
import { Calendar, Modal, Button, Descriptions, message, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getTeams } from "./api/events";
import EventForm from "./components/EventForm";
import EventModal from "./components/EventModal";

dayjs.extend(utc);

const { Option } = Select;

const CalendarPage = () => {
  const [events, setEvents] = useState([]);
  const [teams, setTeams] = useState([]); // State to store teams
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [filterType, setFilterType] = useState("All"); // Existing state for Entity Type filter
  const [selectedTeam, setSelectedTeam] = useState("All"); // New state for Team filter
  const [loading, setLoading] = useState(true); // State for loading indicator

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch events
        const eventsResponse = await api.get(`api/events`);
        setEvents(eventsResponse.data);

        // Fetch teams
        const teamsResponse = await getTeams();
        setTeams(teamsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getListData = (value) => {
    const dateStr = dayjs(value).format("YYYY-MM-DD");
    return events.filter((event) => {
      // Convert the UTC date to local timezone while preserving the date
      const eventDate = dayjs.utc(event.date).format("YYYY-MM-DD");
      const isSameDate = eventDate === dateStr;
      const matchesEntityType =
        filterType === "All" || event.entity_type.toLowerCase() === filterType.toLowerCase();
      const matchesTeam =
        selectedTeam === "All" || event.team_id === selectedTeam;

      return isSameDate && matchesEntityType && matchesTeam;
    });
  };

  const getDotColor = (eventType) => {
    switch (eventType.toLowerCase()) {
      case "session":
        return "green";
      case "match":
        return "red";
      case "meeting":
        return "orange";
      default:
        return "blue"; // Default color
    }
  };

  const cellRender = (current, info) => {
    if (info.type === "date") {
      const listData = getListData(current);
      return (
        <ul className="events" style={{ listStyle: "none", margin: 0, padding: 0 }}>
          {listData.map((item) => (
            <li key={item.event_id} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: getDotColor(item.entity_type),
                  marginRight: "8px",
                  flexShrink: 0 // prevent the dot from shrinking
                }}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the date's onSelect
                  handleViewEvent(item);
                }}
                style={{ cursor: "pointer", fontWeight: "480", color: "black" }}
              >
                {item.event_name}
              </span>
            </li>
          ))}
        </ul>
      );
    } else if (info.type === "month") {
      const monthEvents = events.filter((event) => {
        return dayjs(event.date).month() === current.month() && dayjs(event.date).year() === current.year();
      });

      const eventCounts = monthEvents.reduce((acc, event) => {
        const type = event.entity_type.toLowerCase();
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});

      return (
        <div style={{ textAlign: "center", fontWeight: "480" }}>
          {Object.entries(eventCounts).map(([type, count]) => (
            <div key={type}>
              {`${type.charAt(0).toUpperCase() + type.slice(1)}: ${count}`}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const onSelectDate = (value, info) => {
    if (info.source === "date") {
      const selectedDate = dayjs(value).format("YYYY-MM-DD");
      setCurrentDate(selectedDate);
      setIsAddModalVisible(true);
    }
  };

  const handleViewEvent = (event) => {
    setSelectedEvent(event);
    setIsViewModalVisible(true);
  };

  const handleAddEvent = (eventData) => {
    setEvents([...events, eventData]);
    setIsAddModalVisible(false);
    message.success("Event added successfully!");
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleViewCancel = () => {
    setIsViewModalVisible(false);
    setSelectedEvent(null);
  };

  const handleDeleteEvent = (eventId) => {
    setEvents(events.filter((e) => e.event_id !== eventId));
    setIsViewModalVisible(false);
    setSelectedEvent(null);
  };

  const handleFilterChange = (value) => {
    setFilterType(value);
  };

  const handleTeamFilterChange = (value) => {
    setSelectedTeam(value);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{
      height: "calc(100vh - 48px)",
      overflowY: "auto",
      margin: "-24px",
      padding: "24px",
      position: "relative"
    }}>
      {/* Add Event button standalone */}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setCurrentDate(dayjs().format("YYYY-MM-DD"));
          setIsAddModalVisible(true);
        }}
        style={{ marginBottom: 16 }}
      >
        Add Event
      </Button>

      {/* All filters grouped together */}
      <div style={{
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        marginBottom: 16
      }}>
        <Select
          value={filterType}
          onChange={handleFilterChange}
          style={{ width: 180 }}
          placeholder="All"
        >
          <Option value="All">All</Option>
          <Option value="session">Session</Option>
          <Option value="match">Match</Option>
          <Option value="meeting">Meeting</Option>
        </Select>

        <Select
          value={selectedTeam}
          onChange={handleTeamFilterChange}
          style={{ width: 180 }}
          placeholder="All Teams"
        >
          <Option value="All">All Teams</Option>
          {teams.map((team) => (
            <Option key={team.team_id} value={team.team_id}>
              {team.team_name}
            </Option>
          ))}
        </Select>
      </div>

      <Calendar
        cellRender={cellRender}
        onSelect={onSelectDate}
        fullscreen={true}
      />

      <Modal open={isAddModalVisible} onCancel={handleAddCancel} footer={null} destroyOnClose>
        <EventForm initialValues={{ date: currentDate }} onSave={handleAddEvent} onCancel={handleAddCancel} />
      </Modal>

      <EventModal
        event={selectedEvent}
        visible={isViewModalVisible}
        onClose={handleViewCancel}
        onDelete={handleDeleteEvent}
        onUpdate={(updatedEvent) => {
          setEvents(events.map((e) => (e.event_id === updatedEvent.event_id ? updatedEvent : e)));
          setSelectedEvent(updatedEvent);
        }}
      />
    </div>
  );
};

export default CalendarPage;