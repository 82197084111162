// src/components/Resources.jsx

import React, { useState, useEffect } from "react";
import { Upload, Button, message, Modal } from "antd";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { uploadSessionFile, getSessionFiles, deleteSessionFile, getSessionFileById } from "../api/sessions";
import dayjs from "dayjs";
import PreviewModal from "./PreviewModal";
import FilesTable from "./FilesTable";
import { useAuth } from "../../../hooks/useAuth";

const Resources = ({ sessionId, updateResourceCount }) => {
  const [resources, setResources] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState({});
  const { user } = useAuth();

  // Fetch session files on component mount
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await getSessionFiles(sessionId);
        const files = response.data.map((file) => {
          const url = new URL(file.file_url);
          const fileNameWithToken = url.pathname.split('/').pop();
          const fileName = fileNameWithToken
            .split('?')[0]
            .replace(/^\d+_/, '');
          return {
            key: file.id.toString(),
            id: file.id,
            name: fileName,
            file_url: file.file_url,
            uploadedAt: dayjs(file.created_at).format("YYYY-MM-DD"),
            uploadedBy: user ? `${user.first_name} ${user.last_name}` : "Unknown User",
          };
        });
        setResources(files);
        updateResourceCount(files.length);
      } catch (error) {
        console.error("Failed to fetch session files:", error);
        message.error("Failed to load session files");
      }
    };

    fetchFiles();
  }, [sessionId, user, updateResourceCount]);

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this resource?",
      icon: <ExclamationCircleOutlined />,
      content: `Resource: ${record.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          await deleteSessionFile(record.id);
          setResources(resources.filter((res) => res.id !== record.id));
          message.success("Resource deleted successfully");
        } catch (error) {
          console.error("Failed to delete resource:", error);
          message.error("Failed to delete resource");
        }
      },
      onCancel() {
        // Do nothing
      },
    });
  };

  const handlePreview = async (record) => {
    console.log(`Attempting to preview file ID: ${record.id}, Name: ${record.name}`);
    try {
      console.log('Before regenerating signed URL...');
      const newSignedUrl = await getSessionFileById(sessionId, record.id);
      console.log('Received new signed URL:', newSignedUrl);
      setPreviewFile({ fileUrl: newSignedUrl, fileName: record.name });
      setPreviewVisible(true);
      console.log('Preview file set:', { fileUrl: newSignedUrl, fileName: record.name });
    } catch (error) {
      console.error("Failed to regenerate signed URL:", error);
      message.error("Failed to preview file");
    }
  };

  // Handler for uploading a new resource
  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();

    formData.append("file", file);
    console.log('user', user);
    console.log('user.id', user.id);
    formData.append("uploaded_by", user.id);
    console.log('formData:', formData);
    try {
      console.log("Uploading file:", file.name);
      const response = await uploadSessionFile(sessionId, formData);
      console.log("Upload response:", response);

      const { id } = response.data;

      if (!id) {
        throw new Error("Invalid response from uploadSessionFile");
      }

      const newResource = {
        key: id.toString(),
        id: id,
        name: file.name,
        uploadedAt: dayjs().format("YYYY-MM-DD"),
        uploadedBy: user ? `${user.first_name} ${user.last_name}` : "Unknown User",
      };

      const updatedResources = [...resources, newResource];
      setResources(updatedResources);
      updateResourceCount(updatedResources.length);
      onSuccess("ok");
      message.success(`${file.name} uploaded successfully`);
    } catch (error) {
      console.error("Upload error:", error);
      onError(error);
      message.error(`${file.name} upload failed`);
    }
  };

  return (
    <div>
      <Upload customRequest={handleUpload} showUploadList={false}>
        <Button type="primary" icon={<UploadOutlined />} style={{ marginBottom: 16 }}>
          Upload Resource
        </Button>
      </Upload>

      <FilesTable
        files={resources}
        onPreview={handlePreview}
        onDelete={handleDelete}
      />

      <PreviewModal
        visible={previewVisible}
        onClose={() => setPreviewVisible(false)}
        fileUrl={previewFile.fileUrl}
        fileName={previewFile.fileName}
      />
    </div>
  );
};

export default Resources;
