import React, { useEffect, useState } from "react";
import { Button, Card, Typography, message, Spin, Form, Input, Modal, Descriptions, Divider } from "antd";
import { LogoutOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import supabaseClient from "../../supabaseClient";
import { getUserDetails, updateUserDetails, deleteUserAccount } from "./api/settings";

const { Title, Text } = Typography;

const SettingsPage = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                // Fetch authenticated user from Supabase
                const { data, error } = await supabaseClient.auth.getUser();
                if (error) {
                    throw error;
                }

                const supabaseUser = data.user;

                // Fetch additional user details from the backend using the API utility
                const response = await getUserDetails(supabaseUser.id);
                const userDetails = response.data;

                console.log("Supabase User:", supabaseUser);
                console.log("User Details from Backend:", userDetails);

                // Combine Supabase user data with additional details
                setUser({ ...supabaseUser, ...userDetails });
            } catch (err) {
                console.error("Error fetching user:", err);
                message.error("Failed to fetch user details.");
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, []);

    const handleSignOut = async () => {
        try {
            const { error } = await supabaseClient.auth.signOut();
            if (error) throw error;

            message.success("Signed out successfully.");
            navigate("/login");
        } catch (err) {
            console.error("Error signing out:", err);
            message.error("Failed to sign out. Please try again.");
        }
    };

    const showEditModal = () => {
        form.setFieldsValue({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
        });
        setIsEditModalVisible(true);
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
        form.resetFields();
    };

    const handleEditSubmit = async (values) => {
        try {
            const { first_name, last_name } = values;
            const response = await updateUserDetails(user.id, { first_name, last_name });

            // Update the local state with response data
            setUser((prevUser) => ({
                ...prevUser,
                ...response.data, // Assuming the backend returns the updated user object
            }));

            message.success("User details updated successfully.");
            setIsEditModalVisible(false);
        } catch (error) {
            console.error("Error updating user details:", error);
            message.error("Failed to update user details.");
        }
    };

    const showDeleteModal = () => {
        setIsDeleteModalVisible(true);
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    };

    const handleDeleteConfirm = async () => {
        try {
            // Get the access token first
            const { data: { session } } = await supabaseClient.auth.getSession();
            const accessToken = session?.access_token;

            if (!accessToken) {
                throw new Error('No valid session');
            }

            // Sign out first
            await supabaseClient.auth.signOut();

            // Then delete the account using the saved access token
            await deleteUserAccount(user.id, accessToken);

            message.success("Account deleted successfully.");
        } catch (error) {
            console.error("Error deleting account:", error);
            message.error("Failed to delete account. Please try again.");
        }
    };

    if (loading) {
        return (
            <div style={{ textAlign: "center", marginTop: "20%" }}>
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div style={{
            height: "calc(100vh - 48px)",
            overflowY: "auto",
            margin: "-24px",
            padding: "24px",
            position: "relative",
            background: "#fff",
            overflowX: "auto"
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                minWidth: 'fit-content'
            }}>
                <Card style={{ width: 'fit-content' }}>
                    <Title level={3}>Settings</Title>
                    {user ? (
                        <>
                            <Descriptions
                                bordered
                                column={1}
                                size="small"
                                labelStyle={{ width: '120px' }}
                                contentStyle={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                                style={{ width: 'fit-content' }}
                            >
                                <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                                <Descriptions.Item label="First Name">{user.first_name || 'N/A'}</Descriptions.Item>
                                <Descriptions.Item label="Last Name">{user.last_name || 'N/A'}</Descriptions.Item>
                                <Descriptions.Item label="Created At">
                                    {new Date(user.created_at).toLocaleString()}
                                </Descriptions.Item>
                            </Descriptions>

                            <Divider />

                            <div style={{
                                marginTop: 20,
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'flex-start'
                            }}>
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={showEditModal}
                                >
                                    Edit Details
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<LogoutOutlined />}
                                    onClick={handleSignOut}
                                >
                                    Sign Out
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={showDeleteModal}
                                >
                                    Delete Account
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Text>No user information available. Please log in again.</Text>
                    )}
                </Card>
            </div>

            {/* Edit User Details Modal */}
            <Modal
                title="Edit User Details"
                visible={isEditModalVisible}
                onCancel={handleEditCancel}
                onOk={() => form.submit()}
                okText="Save"
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleEditSubmit}
                >
                    <Form.Item
                        name="first_name"
                        label="First Name"
                        rules={[{ required: true, message: "Please enter your first name" }]}
                    >
                        <Input placeholder="Enter First Name" />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Last Name"
                        rules={[{ required: true, message: "Please enter your last name" }]}
                    >
                        <Input placeholder="Enter Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, message: "Please enter your email" },
                            { type: "email", message: "Please enter a valid email address" },
                        ]}
                    >
                        <Input disabled />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Delete Account Confirmation Modal */}
            <Modal
                title="Confirm Account Deletion"
                visible={isDeleteModalVisible}
                onCancel={handleDeleteCancel}
                onOk={handleDeleteConfirm}
                okText="Delete"
                okType="danger"
            >
                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            </Modal>
        </div>
    );
};

export default SettingsPage;