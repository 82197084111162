// TeamForm.jsx
import api from '../../../../config/api';
import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, InputNumber, Button, Select, message } from "antd";
import dayjs from "dayjs";
import { getAllUsers } from '../../api/teams';

const TeamForm = ({ initialValues, onFinish, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({
    headCoaches: [],
    assistantCoaches: [],
    teamManagers: [],
  });

  useEffect(() => {
    const fetchUsersByRole = async () => {
      try {
        const response = await getAllUsers();
        const usersData = response.data;
        console.log(usersData)

        setUsers({
          headCoaches: usersData,
          assistantCoaches: usersData,
          teamManagers: usersData,
        });
      } catch (error) {
        message.error("Failed to fetch users for roles.");
      }
    };

    fetchUsersByRole();
  }, []);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        team_name: initialValues.teamName,
        team_size: initialValues.teamSize,
        start_date: initialValues.startDate ? dayjs(initialValues.startDate) : null,
        end_date: initialValues.endDate ? dayjs(initialValues.endDate) : null,
        head_coach: initialValues.headCoachId,
        assistant_coach: initialValues.assistantCoachId,
        team_manager: initialValues.teamManagerId,
        team_color: initialValues.team_color, // Set initial color value if editing
      });
    } else {
      form.resetFields();
    }
  }, [initialValues, form]);

  const handleSubmit = (values) => {
    const formattedValues = {
      team_name: values.team_name,
      start_date: values.start_date ? values.start_date.format("YYYY-MM-DD") : null,
      end_date: values.end_date ? values.end_date.format("YYYY-MM-DD") : null,
      team_size: values.team_size,
      head_coach: values.head_coach,
      assistant_coach: values.assistant_coach,
      team_manager: values.team_manager,
      team_color: values.team_color|| "Grey", // Include color in the form submission
    };
    onFinish(formattedValues);
    form.resetFields();
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} autoComplete="off">
      <Form.Item name="team_name" label="Team Name" rules={[{ required: true }]}>
        <Input placeholder="Enter Team Name" />
      </Form.Item>
      <Form.Item name="start_date" label="Start Date" rules={[{ required: true }]}>
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="end_date" label="End Date" rules={[{ required: true }]}>
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item name="team_size" label="Team Size">
        <InputNumber min={1} style={{ width: "100%" }} />
      </Form.Item>
      {/* Color Dropdown */}
      <Form.Item name="team_color" label="Team Color">
        <Select placeholder="Select Color">
          <Select.Option value="Red">Red</Select.Option>
          <Select.Option value="Blue">Blue</Select.Option>
          <Select.Option value="Orange">Orange</Select.Option>
          <Select.Option value="Green">Green</Select.Option>
          <Select.Option value="Black">Black</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item  style={{ textAlign: "right" }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>Cancel</Button>
        <Button type="primary" htmlType="submit" loading={loading} >
          {initialValues ? "Update" : "Create"}
        </Button>
        
      </Form.Item>
    </Form>
  );
};

export default TeamForm;
