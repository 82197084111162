import React from 'react';
import { Modal, Table, Typography, Descriptions, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const FileViewModal = ({
  isVisible,
  onClose,
  drillDetails,
  drillFiles,
  handlePreview,
  handleDeleteFile
}) => {
  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Typography.Link onClick={() => handlePreview(record.id, record.name)}>
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Uploaded At",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => handlePreview(record.id, record.name)}>
                View
              </Menu.Item>
              <Menu.Item onClick={() => handleDeleteFile(record)}>
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <MoreOutlined style={{ cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Modal
      title="Drill Details"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Descriptions bordered column={1} style={{ marginBottom: 16 }}>
        <Descriptions.Item label="Drill Name">{drillDetails?.name}</Descriptions.Item>
        <Descriptions.Item label="Description">{drillDetails?.description}</Descriptions.Item>
        <Descriptions.Item label="Duration">{drillDetails?.duration} min</Descriptions.Item>
      </Descriptions>

      <Typography.Title level={5} style={{ marginTop: 16, marginBottom: 16 }}>
        Files
      </Typography.Title>

      <Table
        columns={columns}
        dataSource={drillFiles}
        rowKey="id"
        pagination={false}
      />
    </Modal>
  );
};

export default FileViewModal;